.subgrupos-tecido {
  .list-group {
    margin-bottom: 10px;
    color: #fff;
    text-transform: uppercase;

    .grupo {
      text-align: center;
    }
  }
}
