.my-orders-page {
  display: flex;
  flex-direction: column;

  .menu {
    height: 52px;
    flex-shrink: 0;
    background-color: rgba($black, 0.3);
    padding: 10px 15px;

    .titulo {
      text-transform: uppercase;
      text-align: center;
      color: #fff;
      font-size: 1.3rem;
      margin: 0 auto;
    }
  }

  .conteudo {
    overflow-y: auto;
    flex: 1;
    margin-top: 15px;

    .listagem {
      background-color: rgba($black, 0.3);
      padding: 0 15px;
      color: #fff;
    }

    .item {
      border-bottom: 1px solid rgba(#fff, 0.25);
      padding: 10px 0;
      display: block;
    }
  }
}
