.grupos-tecido {
  .list-group {
    color: #fff;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);

    .tipo {
      text-align: center;
    }
  }
}

.btn-upload-tecido {
  position: fixed;
  bottom: 20px;
  right: 30px;

  a {
    border-radius: 20px;
    width: 40px;
    height: 40px;
    padding: 10px 5px 10px;

    svg {
      margin-top: -5px;
      width: 22px;
      height: 22px;
      path {
        fill: black;
      }
    }
  }
}
