.dados-faturamento {
  padding: 0 15px;
  color: #fff;
  margin: 15px 0;
  overflow-y: auto;
  overflow: auto;
  max-height: calc(100vh - 15vh - 145px);

  .form-control {
    margin-bottom: 1rem;
  }
}

.editar-endereco-page {
  height: 100vh;
  height: calc(100vh - var(--vh-offset, 0px));
  background-image: linear-gradient(#eb5d40, #eb5d40, #c0172c);
  position: relative;
  .menu {
    z-index: 1;
    display: inline-block;
    .arrow {
      float: left;
      height: 54px;
      padding-top: 10px;
      path {
        fill: gray;
      }
    }
    .div-logo {
      width: calc(100% - 1rem - 21px);
      margin: 0;
      padding-top: 10px;
      padding-left: calc(20vw - 1rem - 21px);
      padding-right: 20vw;
      .logo {
        margin-top: 0;
      }
    }
  }
  .acao {
    position: absolute;
    bottom: 0;
    width: 100vw;
    padding: 0 15vw;
    margin-bottom: 40px;
    right: 0;
    .btn {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 22px;
    }
  }
  .container-fluid {
    margin-top: 50px;
  }
}
