@import './OrdersPage';
@import './OrderPage';

.form-cupom,
.form-cupom:focus {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  border: none;
}
.form-cupom::placeholder {
  color: white;
}

.btn-cupom {
  color: white;
  font-weight: bold;
}
