@import './tipos-modelo';

.modelos {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;

  .modelo {
    width: 50%;
    padding: 5px;
    border: 1px solid rgba(#fff, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      width: 25%;
    }

    @include media-breakpoint-up(lg) {
      width: 16.666666666666668%;
    }

    .svg {
      svg {
        rect[id^='Retângulo'] {
          stroke: transparent !important;
        }
      }
    }

    .label {
      color: #fff;
      text-align: center;
      font-size: 0.8rem;
    }
  }
}

.modelos-by-tipo {
  color: #fff;
  height: 100%;

  .menu {
    padding: 10px 0 5px 10px;
    display: flex;

    > * {
      display: inline-block;
      vertical-align: top;
    }

    span {
      text-align: center;
      flex: 1;
    }
  }
  .box-tecido {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    img {
      width: 100%;
    }

    .sem-tecido {
      display: flex;
      flex: 1;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      background-color: rgba(#000, 0.4);
      color: #fff;
    }

    div {
      position: relative;
      .image-remove {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        opacity: 0.7;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}

.modelo-selecionado {
  display: flex;
  flex-basis: 20%;
  flex-shrink: 0;

  @include media-breakpoint-up(lg) {
    height: 100%;
    overflow: hidden;
  }

  > .opcoes {
    width: 100%;
    overflow-y: auto;

    .tecido {
      width: 100%;
      height: 100%;
      padding: 10px;
      display: flex;

      img {
        width: 100%;
      }

      .sem-tecido {
        display: flex;
        flex: 1;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        background-color: rgba(#000, 0.4);
        color: #fff;
      }

      div {
        position: relative;
        .image-remove {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          opacity: 0.7;

          svg {
            path {
              fill: #000;
            }
          }
        }
      }
    }
    .selected-model-variations .variations .variations-inner,
    .variacoes {
      padding: 10px;

      .selected-model-variation,
      .tipo {
        color: #fff;
        text-align: center;
        margin-bottom: 15px;

        .title,
        .titulo {
          text-transform: uppercase;
        }

        ul,
        .opcoes {
          @extend .list-group;
          font-size: 0.8rem;

          li.nav-item {
            @extend .list-group-item;
            padding: 0;

            .nav-link {
              padding: 12px 20px;
            }
          }
        }
      }
    }
  }
}

.modelos-by-subgrupo-tecido {
  @extend .modelos-by-tipo;
}
