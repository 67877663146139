.compra-molde-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: calc(100vh - var(--vh-offset, 0px));

  .menu {
    height: 50px;
    padding: 10px;
  }

  .passo {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #fff;

    .content {
      flex: 1;
      flex-direction: column;
      padding: 0 20px 20px;
      color: gray;
      overflow-y: auto;

      .formulario,
      form {
        background-color: white;
        height: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        input {
          color: gray;
          border: 1px solid #ced4da;

          &::-webkit-input-placeholder {
            color: gray;
          } /* WebKit, Blink, Edge */
          &:-moz-placeholder {
            color: gray;
          } /* Mozilla Firefox 4 to 18 */
          &::-moz-placeholder {
            color: gray;
          } /* Mozilla Firefox 19+ */
          &:-ms-input-placeholder {
            color: gray;
          } /* Internet Explorer 10-11 */
          &::-ms-input-placeholder {
            color: gray;
          } /* Microsoft Edge */
          &:focus {
            color: gray !important;
            ::placeholder {
              color: gray !important;
            }
          }
          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus {
            color: gray;
            ::placeholder {
              color: gray;
            }
          }
        }
        .body {
          flex: 2;
        }

        .feedback {
          width: 80%;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          margin-bottom: 20px;
        }

        .footer {
          flex: 1;
          p {
            margin-bottom: 0px;
            font-size: 14px;
          }
        }

        .titulo {
          flex: 1;
          p {
            font-size: 20px;
          }
        }

        .mensagem-sucesso {
          color: gray;
        }
      }
    }

    .acao {
      height: 48px;
    }
  }
}

.selecao-tamanhos-modelo {
  .scrollable-content {
    overflow-y: auto;
    height: 100%;
  }

  .content {
    .tamanho {
      background-color: white;
      font-size: 1rem;
      line-height: 1rem;
      padding: 0 20px;

      .row {
        border-bottom: 1px solid rgba(gray, 0.2);
        padding: 15px 0;
        margin: 0px;
        position: relative;

        > div {
          display: flex;
          align-self: center;
          white-space: nowrap;
          padding: 0;
        }

        .descricao {
          justify-content: flex-end;
        }

        .seletor {
          justify-content: center;
          .onoffswitch {
            display: inline-block;
          }
        }

        .valor {
          justify-content: flex-end;
        }

        .modelo-prova {
          margin-left: -30px;
          position: absolute;
          top: 50%;
          margin-top: -18px;
        }
      }

      &:last-child {
        .d-flex {
          border-bottom: none;
        }
      }
    }
  }
}

.confirmacao-compra-molde {
  .conteudo {
    padding: 20px;
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .detalhes {
    flex: 1;
    display: flex;

    .manequim {
      flex: 1;

      svg {
        max-height: 60vh;
      }
    }

    .tamanhos {
      flex: 1;
      display: flex;
      flex-direction: column;

      .tamanho {
        display: flex;
        padding: 5px 0;

        > div {
          flex: 1;
          position: relative;

          .tamanho-prova {
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -18px;
          }
        }

        .descricao {
          display: flex;
          justify-content: flex-end;
          font-size: 0.9rem;
          white-space: nowrap;
        }
      }
    }

    p.titulo {
      font-size: 20px;
    }
  }

  .total {
    text-align: center;
    margin-bottom: 0px;
    font-size: 20px;
  }

  .box-total {
    width: 50%;
    margin: 0 auto;
    border: solid 1px;
    text-align: center;
    padding: 10px 0px;
    font-size: 20px;
  }
}

.sucesso-compra-molde {
  .content {
    display: flex;
    > * {
      background-color: white;
      flex: 1;
      display: flex;
      align-items: center;
      padding: 15px;
      text-align: center;
      justify-content: center;

      a {
        color: gray;
      }
    }
  }

  h2 {
    color: gray;
    text-align: center;
  }
}

.tamanhos-selecionados {
  .content {
    table {
      background-color: white;
      .item {
        padding: 10px 0px;
        border-bottom: 1px solid rgba(gray, 0.2);
      }

      td,
      th {
        padding: 0px;
        position: relative;

        .modelo-prova {
          position: absolute;
          margin-left: -100%;
          margin-top: 25%;
        }
      }

      .selecionado {
        background-color: #00c4c9;
        width: 10px;
      }
    }
  }
}
