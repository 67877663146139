@import 'forms';

.topo-pedido {
  flex-direction: column;
  margin-bottom: 20px;

  .quadrado {
    background-color: white;
    height: 15vh;
    margin-bottom: -1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .logo {
      display: block;
      margin: auto;
      margin-top: 9vh;
      margin-bottom: 0px;
    }

    .mensagem {
      z-index: 3;
      text-align: center;
      font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 18px;
      font-weight: bold;
      color: #b9162a;
      margin-top: -10px;
      margin-bottom: 0px;
    }
  }
  .meio-circulo {
    z-index: 2;
  }
}

.topo-registro {
  height: 24vh !important;
}

.topo {
  flex-direction: column;

  .quadrado {
    background-color: white;
    height: 33vh;
    margin-bottom: -1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    :first-child {
      width: 70vw;
      height: calc((70vw / 264.001) * 34.932);
      margin: auto;
      margin-top: 0;
    }

    .logo {
      display: block;
      margin: auto;
      margin-top: 9vh;
      margin-bottom: 0px;
    }

    .mensagem {
      text-align: center;
      font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 20px;
      font-weight: bold;
      color: #b9162a;
      margin-top: -10px;
      margin-bottom: 0px;
    }
  }
}

.arrow {
  opacity: 80%;
}
