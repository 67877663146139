@import 'amostras/amostras-tecido';
@import 'grupos/grupos-tecido';
@import 'subgrupos/subgrupos-tecido';
@import 'tecidos-subgrupo/tecidos-subgrupo-tecido';

.tecidos-page {
  display: flex;
  flex-direction: column;

  .menu {
    margin: 15px 15px 0;
    padding: 10px 15px 5px 15px;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 1.2rem;
  }

  .catalogo {
    flex: 1;
    margin: 0 15px;
    overflow-y: auto;
  }
}
