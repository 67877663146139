.amostras-tecido {
  background-color: rgba($black, 0.3);
  color: #fff;

  .tecido {
    text-align: center;
    text-transform: uppercase;
    padding: 15px;
    border-bottom: 1px solid rgba(#fff, 0.2);
  }

  .amostras {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;

    .amostra {
      width: 50%;
      padding: 10px;

      @include media-breakpoint-up(sm) {
        width: 25%;
      }

      @include media-breakpoint-up(lg) {
        width: 20%;
      }

      img {
        width: 100%;
      }
    }
  }
}
