.carrinho-compras-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: calc(100vh - var(--vh-offset, 0px));

  .menu {
    height: 52px;
    flex-shrink: 0;
    background-color: rgba($black, 0.3);
    padding: 10px 15px;

    .total {
      text-transform: uppercase;
      text-align: right;
      color: #fff;
      font-size: 1.3rem;
    }
  }

  .container-fluid {
    flex: 1;
    overflow-y: auto;

    .itens {
      background-color: rgba($black, 0.3);
      padding: 0 15px;
      color: #fff;
      margin: 15px 0;

      svg {
        max-width: 80px;
      }

      .description {
        flex: 1;
      }

      .item {
        border-bottom: 1px solid rgba(#fff, 0.1);
        padding: 10px 0;
      }
    }
  }

  .actions {
    height: 48px;
    flex-shrink: 0;
  }
}
