@import '../../desktop/auth/LoginPage';

.login-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  align-items: center;
  background-image: linear-gradient(#eb5d40, #eb5d40, #c0172c);

  .formulario {
    flex: 4;
    width: 80vw;
    display: flex;
  }

  .validate-user-password-form {
    .bem-vindo {
      display: none;
    }
  }

  .verify-user-email-form {
    .conta-gratuita {
      display: none;
    }

    .verify-your-email-title {
      font-size: 1.5rem;
      color: #fff;
    }
  }

  .request-password-recover {
    .content {
      color: #fff;

      * {
        color: inherit;
      }

      h1 {
        display: none;
      }

      h2,
      p {
        font-size: 1.5rem;
      }
    }
  }
}
