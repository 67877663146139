.tipos-modelo {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;

  .tipo-modelo {
    width: 50%;
    padding: 5px;
    border: 1px solid rgba(#fff, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      width: 25%;
    }

    @include media-breakpoint-up(lg) {
      width: 16.666666666666668%;
    }

    .svg {
      svg {
        rect[id^='Retângulo'] {
          stroke: transparent !important;
        }
      }
    }

    .label {
      color: #fff;
      text-align: center;
      font-size: 0.8rem;
    }
  }

  .box-tecido {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    img {
      width: 100%;
    }

    .sem-tecido {
      display: flex;
      flex: 1;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      background-color: rgba(#000, 0.4);
      color: #fff;
    }

    div {
      position: relative;
      .image-remove {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        opacity: 0.7;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}
