@import 'models/modelos';
@import 'tecidos/tecidos';
@import 'tecidos-usuario/tecidos-usuario';

.catalogo-page {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .navegacao {
    height: 100%;
    flex: 1;
    display: flex;
    overflow: hidden;

    .passarela {
      flex-basis: 60%;
      position: relative;
      padding: 50px 0;
      overflow: hidden;
      height: 100%;

      @include media-breakpoint-up(sm) {
        flex-basis: 40%;
      }
      @include media-breakpoint-up(lg) {
        flex-basis: 33%;
      }

      .virar-modelo {
        position: fixed;
        bottom: 5px;
        left: 10px;

        a {
          display: block;
        }
      }

      .btn-upload-tecido {
        position: fixed;
        bottom: 5px;
        left: calc(60% - 50px);

        @include media-breakpoint-up(sm) {
          left: calc(40% - 50px);
        }
        @include media-breakpoint-up(lg) {
          left: calc(33% - 50px);
        }

        a {
          border-radius: 20px;
          width: 40px;
          height: 40px;
          padding: 10px 5px 10px;

          svg {
            margin-top: -5px;
            width: 22px;
            height: 22px;
            path {
              fill: black;
            }
          }
        }
      }

      &.permite-compra {
        .virar-modelo,
        .btn-upload-tecido {
          bottom: 55px;
        }
      }
    }

    .catalogo {
      flex-basis: 40%;

      @include media-breakpoint-up(sm) {
        flex-basis: 60%;
      }

      @include media-breakpoint-up(lg) {
        flex-basis: 67%;
        display: flex;
      }

      background-color: rgba($black, 0.2);
      height: 100%;
      overflow-y: auto;
    }

    .compra {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.manequim {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  position: relative;

  svg {
    width: 100%;
    height: 100%;
  }
}
