@import 'StepAcceptContract';

.novo-pedido-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: calc(100vh - var(--vh-offset, 0px));
  background-image: linear-gradient(#eb5d40, #eb5d40, #c0172c);

  .passo {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 0;
    .conteudo {
      flex: 1;

      p {
        font-size: 20px;
        text-align: center;
      }

      h4 {
        color: #fff;
      }

      .listagem {
        background-color: rgba($black, 0.4);
        padding: 10px 0;
        color: #fff;
        font-size: 14px;
        p {
          font-size: 14px;
          margin-bottom: 8px;
        }
        .item {
          margin: 0 15px;
        }
      }

      .resumo-pedido {
        .listagem {
          padding: 0 5vw;
        }
      }

      .listagem-produtos {
        max-height: 37vh;
        overflow-y: auto;
      }

      .item {
        padding: 5px 0;

        .descricao {
          flex: 1;
        }
      }

      .item:not(:last-child) {
        border-bottom: 1px solid rgba(#fff, 0.8);
      }

      .endereco-escolhido {
        background-color: rgba($black, 0.5);
      }

      .checked {
        svg {
          path {
            fill: #00c4c9;
          }
        }
      }

      .adicionar-endereco {
        font-size: 18px;
        font-weight: bold;
        text-decoration: underline;
      }

      .enderecos {
        overflow: auto;
        padding: 0 0;
        .primeira-linha {
          svg {
            width: 20px;
          }
          strong {
            position: relative;
            margin: auto;
            left: -10px;
          }
        }
        .icones {
          text-align: center;
          a {
            margin: 0 15px;
          }
        }
      }
    }

    .acao {
      padding: 0 15vw;
      margin-bottom: 40px;
      @media only screen and (max-width: 375px) {
        margin-bottom: 15px;
      }
      margin-top: 20px;
      .btn {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 22px;
      }
    }
    .selecionar-endereco {
      padding: auto;
      padding-left: 15vw;
      padding-right: 15vw;
    }
    .resumo-pedidos {
      padding: 20px 5vw 0 5vw;
      .total {
        margin-left: auto;
        .riscado {
          text-decoration: overline;
        }
      }
      .editar-pedido {
        svg {
          display: block;
          margin: auto;
          margin-top: 5px;
        }
      }
    }
  }
  .menu {
    z-index: 1;
    display: inline-block;

    div {
      h4 {
        text-align: center;
        div {
          margin: auto;
          width: 46px;
          height: 46px;
          color: #b9162a;
          border-radius: 100%;
          vertical-align: middle;
          padding-top: 9px;
        }
        div.atual {
          color: white;
          background: #b9162a;
        }
      }
    }
  }
}
.novo-pedido-page {
  .menu {
    .arrow {
      float: left;
      height: 54px;
      padding-top: 10px;
      path {
        fill: gray;
      }
    }
    .div-logo {
      width: calc(100% - 1rem - 21px);
      margin: 0;
      padding-top: 10px;
      padding-left: calc(20vw - 1rem - 21px);
      padding-right: 20vw;
      .logo {
        margin-top: 0;
      }
    }
  }
}
