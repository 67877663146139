.recover-page {
  h2 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 50px;
  }
  button.btn-custom {
    width: 80%;
  }
}
