.upload-tecido-page {
  display: flex;
  flex-direction: column;
  color: #fff;
  height: 100vh;

  .menu {
    height: 52px;
    flex-shrink: 0;
    background-color: rgba($black, 0.3);
    color: #fff;
    padding: 10px 15px;

    .titulo {
      text-align: center;
      text-transform: uppercase;
      font-size: 1.3rem;
    }
  }

  form {
    flex: 1;
    margin: 15px;
    overflow-y: auto;

    .acao {
      > button {
        > div {
          width: 40px;
          display: inline-block;

          svg {
            width: 100%;
          }
        }
      }
    }
    .form-group {
      label {
        color: #fff !important;
      }
    }
    .imagem {
      position: relative;
      .excluir-imagem {
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }
    .box-imagem {
      width: 100%;
      height: 100%;
      padding: 10px;
      display: flex;
      text-align: center;
      background-color: rgba(#000, 0.4);
      color: #fff;
      justify-content: center;
      flex: 1;
      flex-direction: column;

      .inputfile {
        display: none;
      }

      div {
        position: relative;
        .image-remove {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          opacity: 0.7;

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
}
