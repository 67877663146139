input.form-control {
  background-color: transparent;
  color: #fff;
  border: 1px solid white;
  border-style: none none solid none;
  font-size: 16px;

  &:disabled {
    background-color: inherit;
    color: #bbb;

    &::-webkit-input-placeholder {
      color: #bbb;
    } /* WebKit, Blink, Edge */
    &:-moz-placeholder {
      color: #bbb;
    } /* Mozilla Firefox 4 to 18 */
    &::-moz-placeholder {
      color: #bbb;
    } /* Mozilla Firefox 19+ */
    &:-ms-input-placeholder {
      color: #bbb;
    } /* Internet Explorer 10-11 */
    &::-ms-input-placeholder {
      color: #bbb;
    } /* Microsoft Edge */
  }

  &::-webkit-input-placeholder {
    color: white;
  } /* WebKit, Blink, Edge */
  &:-moz-placeholder {
    color: white;
  } /* Mozilla Firefox 4 to 18 */
  &::-moz-placeholder {
    color: white;
  } /* Mozilla Firefox 19+ */
  &:-ms-input-placeholder {
    color: white;
  } /* Internet Explorer 10-11 */
  &::-ms-input-placeholder {
    color: white;
  } /* Microsoft Edge */
  &:focus {
    background-color: transparent;
    color: #fff !important;
    border: 1px solid white;
    border-style: none none solid none;
    font-size: 16px;
    box-shadow: none;
    ::placeholder {
      color: #fff !important;
    }
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: transparent;
    color: #fff;
    border: 1px solid white;
    border-style: none none solid none;
    font-size: 14pt;
    ::placeholder {
      color: #fff;
    }
  }
}

input:-internal-autofill-selected {
  background-color: transparent;
  color: #fff !important;
  border: 1px solid white;
  border-style: none none solid none;
  font-size: 14pt;
}

input:-webkit-autofill {
  background-color: transparent;
  color: #fff !important;
  border: 1px solid white;
  border-style: none none solid none;
  font-size: 14pt;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  border-bottom: 1px solid #fff;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: unset;
  transition: background-color 5000s ease-in-out 0s;
}
