@import './UploadTecidoUsuarioPage';

.tecidos-usuario {
  display: flex;
  flex-direction: column;
  color: #fff;

  .menu {
    height: 52px;
    flex-shrink: 0;
    background-color: rgba($black, 0.3);
    color: #fff;
    padding: 10px 15px;

    .titulo {
      text-align: center;
      text-transform: uppercase;
      font-size: 1.3rem;
    }
  }

  .conteudo {
    flex: 1;
    overflow-y: auto;

    .listagem {
      background-color: rgba($black, 0.3);
      display: flex;
      flex-wrap: wrap;
      margin: 10px;
      flex-direction: row;
      padding: 5px;

      .tecido-usuario {
        width: 50%;
        padding: 5px;

        @include media-breakpoint-up(sm) {
          width: 25%;
        }

        @include media-breakpoint-up(lg) {
          width: 20%;
        }
        div {
          position: relative;
          width: 100%;
          img {
            width: 100%;
          }

          .remover-imagem {
            position: absolute;
            top: 0;
            right: 0;
            padding: 5px;
            opacity: 0.7;
          }

          .info-imagem {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 5px;
          }
        }
      }
    }
  }
}
